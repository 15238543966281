import * as React from 'react'
import { Link } from 'gatsby'
import Seo from '../components/seo'
// import Video from '../components/video'
import Layout from '../components/layout'

// markup
const NotFoundPage = () => {
  return (
    <>
      <Seo title='Stranica ne postoji' />
      <Layout>
        <h2 className='text-6xl'>Nažalost tražena stranica ne postoji :-(</h2>
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            Sadržaj nije generiran, pokušaj stvoriti stranicu u{' '}
            <code>src/pages/</code> ili <code>src/markdown-pages/</code>
            <br />
          </>
        ) : null}
        <br />
        <Link to='/'>Idi na naslovnu stranicu...</Link>.
      </Layout>
    </>
  )
}

export default NotFoundPage
